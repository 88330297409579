<template>
  <div class="container">
    <div class="div1">企业荣誉</div>
    <div class="div2">Enterprise honor</div>


    <div class="con">

      <div class="div3" v-for="(item,index)  in  data" :key="index">
        <div class="div4">
          <img :src="item.pic" alt="">
        </div>
        <div class="div5">{{item.title}}</div>
      </div>

    </div>
  </div>
</template>


<script>
  import {getHonorList} from "../../api/zoujindaweijia"
  export default {
    name: "C",
    data(){
      return{
        data:[]
      }
    },
    mounted() {
      getHonorList().then(res=>{
        // console.log(res.data);
        let data = res.data;
        // console.log(data);
        this.data = data;
      })

    }
  }
</script>


<style scoped>
  .container{
    /*height: 52.8646vw;*/
  }
  .div1{
    height: 2.0833vw;
    font-size: 2.2917vw;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    color: #d7000f;
    margin-top: 4.1146vw;
    text-align: center;
    letter-spacing:0.15vw;
  }
  .div2{
    height: 1.0417vw;
    font-size: 1.3542vw;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #646464;
    margin-top: 1.0417vw;
    text-align: center;
  }

  .con{
    margin-top: 5.2083vw;
    margin-bottom: 7.8125vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: 5%;
    /*background-color: pink;*/

  }
   .div3{
  width: 25.313vw;
   /*height: 22.92vw; */
  margin: 0 1.5625vw 1.5625vw 1.5625vw;
  /*width: calc((100% - 0.5208vw) / 5);*/
  min-width: calc((100% - 0.5208vw) / 5);
  max-width: calc((100% - 0.5208vw) / 5);
  /* background-color: pink; */
    /*&:nth-child(3n) {*/
       /*margin-right: 0;*/
     }
  .div4{
    text-align: center!important;
    margin: 0 auto!important;
     height: 14.5833vw;
    /*background-color: green;*/
    border: 0.1vw solid #c6c6c6;
  }
  img{
    height: 14.5833vw;
    line-height:14.5833vw;
    max-width: 100%;
    max-height: 13vw;
    margin-top: 0.5vw;

  }
  .div5{
    height: 3.6458vw;
    font-size: 1.0417vw;
    line-height: 3.6458vw;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: center;
    color: #000000;
    /*background-color: pink;*/
  }
</style>